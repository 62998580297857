export const sectionMenu = function (button) {
  if (button) {
    handleButton(button)
  } else {
    const sectionMenuButtons = document.querySelectorAll('[data-js-open-section-menu]')

    sectionMenuButtons.forEach(function (sectionMenuButton) {
      handleButton(sectionMenuButton)
    })
  }
}

function handleButton (sectionMenuButton) {
  sectionMenuButton.addEventListener('click', setAriaState, false)

  document.addEventListener('click', function (event) {
    if (event.target !== sectionMenuButton && event.target !== event.target.parentNode.querySelector('input[type=radio]')) {
      sectionMenuButton.setAttribute('aria-expanded', 'false')
    }
  })
}

function setAriaState () {
  const expanded = this.getAttribute('aria-expanded')

  if (expanded === 'false') {
    this.setAttribute('aria-expanded', true)
  } else {
    this.setAttribute('aria-expanded', false)
  }
}

export const fakeSelect = function (fakeSelect) {
  if (fakeSelect) {
    const button = fakeSelect.querySelector('button')

    sectionMenu(button)

    const realSelect = fakeSelect.querySelector('select')

    fakeSelect.querySelectorAll('input[type=radio]')
      .forEach((listItem) => {
        function selectHandler (event) {
          // Click Events
          if ((event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) || event.key === 'Enter') {
            const value = event.target.value
            const option = realSelect.querySelector('[value="' + value + '"]')
            button.querySelector('span').textContent = option.textContent
            button.setAttribute('aria-expanded', 'false')
            option.selected = 'selected'
          }
        }

        listItem.addEventListener('keyup', selectHandler)
        listItem.addEventListener('click', selectHandler)
      })
  }
}

export const initFakeSelects = function () {
  const fakeSelects = document.querySelectorAll('[data-js-fake-select]')

  fakeSelects.forEach(function (select) {
    fakeSelect(select)
  })
}
