import { scrollToElement } from './scroll-to-element'

const accordionDetails = document.querySelectorAll('[data-js-accordion] [data-js-accordion-details]')
accordionDetails
  .forEach(details => details.addEventListener('toggle', toggleDetails))

initLinksToAccordionDetails()

function toggleDetails (event) {
  if (!event.target.open) return

  const container = event.target.closest('[data-js-accordion]')

  for (const details of container.querySelectorAll('[data-js-accordion-details]')) {
    if (details !== event.target) {
      details.open = false
    }
  }
}

export function initLinksToAccordionDetails () {
  const linksToAccordionDetails = document.querySelectorAll('[href^="#acc-item"]')

  linksToAccordionDetails.forEach(link => {
    link.addEventListener('click', function (event) {
      const linkTarget = link.getAttribute('href')
      const linkedDetailsSelector = document.querySelector(linkTarget)

      if (linkedDetailsSelector) {
        linkedDetailsSelector.open = true

        event.preventDefault()
        scrollToElement(linkedDetailsSelector)
      }
    }, false)
  }, false)
}
