export function scrollToElement (el, delay) {
  const scrollToElement = el || document.querySelector('[data-js-scroll-to]')
  const timeout = delay || 0

  if (scrollToElement) {
    const previousTabIndex = scrollToElement.getAttribute('tabindex')
    scrollToElement.setAttribute('tabindex', '-1')
    scrollToElement.focus({ preventScroll: true })
    if (previousTabIndex !== null) {
      scrollToElement.setAttribute('tabindex', previousTabIndex)
    } else {
      scrollToElement.removeAttribute('tabindex')
    }

    setTimeout(function () {
      scrollToElement.scrollIntoView({
        behavior: 'smooth'
      })
    }, timeout)
  }
}
